var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('AuthWrapper',{attrs:{"show-rectangles":false},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div')]},proxy:true},{key:"card",fn:function(){return [_c('div',[_c('validation-observer',{ref:"personalForm"},[_c('form-wizard',{staticClass:"wizard-vertical mb-3",attrs:{"color":"#144F6A","title":null,"subtitle":null,"layout":"horizontal","step-size":'xs',"finish-button-text":"Submit","back-button-text":"Prev"},on:{"on-complete":_vm.onComplete}},[_c('tab-content',{attrs:{"title":"Personal Information","before-change":_vm.validatePersonalInfo}},[_c('validation-observer',{ref:"personalInfo",attrs:{"tag":"form"}},[_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"First name","labbel-for":"firstname"}},[_c('validation-provider',{attrs:{"name":"Firstname","vid":"Firstname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.first_name),expression:"first_name"}],attrs:{"id":"firstname","placeholder":"Enter your first name"},domProps:{"value":(_vm.first_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.first_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Last name","labbel-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Last_name","vid":"Last_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.last_name),expression:"last_name"}],attrs:{"id":"last_name","placeholder":"Enter your last name"},domProps:{"value":(_vm.last_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.last_name=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1),_c('tab-content',{attrs:{"title":"Contact Information","before-change":_vm.validateContactInfo}},[_c('validation-observer',{ref:"contactInfo",attrs:{"tag":"form"}},[_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Email","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"id":"firstname","placeholder":"Enter your first name"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Phone Number","labbel-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],attrs:{"id":"lastname","placeholder":"Enter your last name"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1),_c('tab-content',{attrs:{"title":"Loan Information","before-change":_vm.validateLoanInfo}},[_c('validation-observer',{ref:"loanInfo",attrs:{"tag":"form"}},[_c('div',{staticClass:"mb-2"},[_c('b-form-checkbox',{attrs:{"name":"ezwich_application"},model:{value:(_vm.ezwich_application),callback:function ($$v) {_vm.ezwich_application=$$v},expression:"ezwich_application"}},[_vm._v(" Apply for Ezwich ")])],1),(_vm.ezwich_application)?_c('div',[_c('div',[_c('b-form-group',{attrs:{"label":"Zenith Bank Branch","label-for":"preferred bank branch"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customFieldOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"user-status"},model:{value:(_vm.bank_branch),callback:function ($$v) {_vm.bank_branch=$$v},expression:"bank_branch"}})],1)],1)]):_vm._e(),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Nss ID","label-for":"nss_pin"}},[_c('validation-provider',{attrs:{"name":"nss id","vid":"nss id","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.nss_id),expression:"nss_id"}],attrs:{"id":"nss_id","placeholder":"1234567890"},domProps:{"value":(_vm.nss_id)},on:{"input":function($event){if($event.target.composing){ return; }_vm.nss_id=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"mb-2"},[_c('b-form-group',{attrs:{"label":"Purpose for Loan","label-for":"loan_purpose"}},[_c('validation-provider',{attrs:{"name":"loan_purpose","vid":"loan_purpose","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"exxtra-input"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.loan_purpose),expression:"loan_purpose"}],attrs:{"id":"loan_purpose","placeholder":"Enter your last name"},domProps:{"value":(_vm.loan_purpose)},on:{"input":function($event){if($event.target.composing){ return; }_vm.loan_purpose=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)])],1)],1)],1)],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }