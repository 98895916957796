<template>
  <section>
    <AuthWrapper :show-rectangles="false">
      <template #heading>
        <div>

        </div>
      </template>

      <template #card>

        <div>
          <validation-observer ref="personalForm">
              <form-wizard
              color="#144F6A"
              :title="null"
              :subtitle="null"
              layout="horizontal"
              :step-size="'xs'"
              finish-button-text="Submit"
              class="wizard-vertical mb-3"
              back-button-text="Prev"
              @on-complete="onComplete"
          >



                <!-- Personal Information                -->
                <tab-content
                    title="Personal Information"
                    :before-change="validatePersonalInfo"
                >
                  <validation-observer ref="personalInfo" tag="form">
                    <div class="mb-2">
                      <b-form-group
                          label="First name"
                          labbel-for="firstname"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Firstname"
                            vid="Firstname"
                            rules="required"
                        >
                          <div class="exxtra-input">
                            <input id="firstname" v-model="first_name" placeholder="Enter your first name">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="mb-2">
                      <b-form-group
                          label="Last name"
                          labbel-for="last_name"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Last_name"
                            vid="Last_name"
                            rules="required"
                        >
                          <div class="exxtra-input">
                            <input id="last_name" v-model="last_name" placeholder="Enter your last name">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </validation-observer>
                </tab-content>

                <!-- Contact information-->
                <tab-content
                    title="Contact Information"
                    :before-change="validateContactInfo"
                >
                  <validation-observer ref="contactInfo" tag="form">
                    <div class="mb-2">
                      <b-form-group
                          label="Email"
                          label-for="Email"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Email"
                            vid="Email"
                            rules="required|email"
                        >
                          <div class="exxtra-input">
                            <input id="firstname" v-model="email" placeholder="Enter your first name">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="mb-2">
                      <b-form-group
                          label="Phone Number"
                          labbel-for="phone"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="Phone"
                            vid="phone"
                            rules="required"
                        >
                          <div class="exxtra-input">
                            <input id="lastname" v-model="phone" placeholder="Enter your last name">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </validation-observer>
                </tab-content>

                <!-- Loan Information                -->
                <tab-content
                    title="Loan Information"
                    :before-change="validateLoanInfo"
                >
                  <validation-observer
                      ref="loanInfo"
                      tag="form"
                  >
                    <div class="mb-2">
                      <b-form-checkbox
                          v-model="ezwich_application"
                          name="ezwich_application"
                      >
                        Apply for Ezwich
                      </b-form-checkbox>
                    </div>

                    <div v-if="ezwich_application">
                      <div>
                        <b-form-group
                            label="Zenith Bank Branch"
                            label-for="preferred bank branch"
                        >
                          <v-select
                              v-model="bank_branch"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="customFieldOptions"
                              :reduce="val => val.value"
                              :clearable="false"
                              input-id="user-status"
                          />
                        </b-form-group>
                      </div>
                    </div>

                    <div class="mb-2">
                      <b-form-group
                          label="Nss ID"
                          label-for="nss_pin"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="nss id"
                            vid="nss id"
                            rules=""
                        >
                          <div class="exxtra-input">
                            <input id="nss_id" v-model="nss_id" placeholder="1234567890">
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div class="mb-2">
                      <b-form-group
                          label="Purpose for Loan"
                          label-for="loan_purpose"
                      >
                        <validation-provider
                            #default="{ errors }"
                            name="loan_purpose"
                            vid="loan_purpose"
                            rules="required"
                        >
                          <div class="exxtra-input">
                            <textarea id="loan_purpose" v-model="loan_purpose" placeholder="Enter your last name">
                            </textarea>
                          </div>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </validation-observer>
                </tab-content>

              </form-wizard>
          </validation-observer>
        </div>


      </template>
    </AuthWrapper>
  </section>

</template>

<script>
import { get } from "lodash";
import vSelect from 'vue-select';
import { required } from '@validations';
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import AuthWrapper from "@core/components/auth-wrapper/AuthWrapper.vue";
import { BButton, BFormGroup, BFormCheckbox, BLink, BForm } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "Personal",
  components: {
    FormWizard,
    TabContent,
    vSelect,
    AuthWrapper,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BButton,
    BFormGroup,
    BLink,
    BForm
  },
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      nss_id: "",
      loan_purpose: "",
      bank_branch: "",
      ezwich_application: false,
      customFieldOptions: [
        { label: 'Head Office Branch', value: 'Head Office Branch', description: "" },
        { label: 'Achimota Branch', value: 'Achimota Branch', description: " Adjacent Neoplan (Accra – Nsawam Rd, Achimota)" },
        { label: 'Adum Branch', value: 'Adum Branch', description: "Bogyawe Road (Opposite Nakani and Hagan)" },
        { label: 'Akosombo Branch', value: 'Akosombo Branch', description: "Church Ridge" },
        { label: 'Bolgatanga Branch', value: 'Bolgatanga Branch', description: "Bolgatanga" },
        { label: 'Bui Agency', value: 'Bui Agency', description: "Bui Agency" },
        { label: 'CapeCoast Branch', value: 'CapeCoast Branch', description: "UCC New Site" },
        { label: 'East Legon Branch', value: 'East Legon Branch', description: "East Legon Branch" },
        { label: 'Free Zones Branch', value: 'Free Zones Branch', description: "Tema Export Processing Zones ( Kpone )" },
        { label: 'Graphic Road Branch', value: 'Graphic Road Branch', description: "Graphic Road Branch" },
        { label: 'GREL Agency', value: 'GREL Agency', description: 'Main Building, Ghana Rubber Estate Ltd. - Abora' },
        { label: 'Ho Branch', value: 'Ho Branch', description: 'Ho Technical University Campus' },
        { label: 'Kantamanto Agency', value: 'Kantamanto Agency', description: 'Tarzan House' },
        { label: 'KNUST Agency', value: 'KNUST Agency', description: "Jubiee Mall Complex, Kumasi" },
        { label: 'Koforidua Branch', value: 'Koforidua Branch', description: 'Koforidua' },
        { label: 'Kojo Thompson Road Branch', value: 'Kojo Thompson Road Branch', description: 'Kojo Thompson Road Branch' },
        { label: 'Kotoka International Airport Agency', value: 'Kotoka International Airport Agency' },
        { label: 'KotoKuraba Market Agency', value: 'KotoKuraba Market Agency', description: 'KotoKuraba Market Agency' },
        { label: 'Kumasi Main Branch', value: 'Kumasi Main Branch', description: 'Ahodwo Road, Adiebeba, Kumasi' },
        { label: 'Kumasi Polytechnic Agency', value: 'Kumasi Polytechnic Agency', description: 'Kumasi Polytechnic Agency' },
        { label: 'Labone Branch', value: 'Labone Branch', description: 'Labone Branch' },
        { label: 'North Industrial Area Branch', value: 'North Industrial Area Branch', description: 'North Industrial Area Branch' },
        { label: 'Patrice Lumumbra Branch', value: 'Premier Towers Branch', description: 'Premier Towers Branch' },
        { label: 'Sakaman Branch', value: 'Sakaman Branch', description: 'Sakaman Branch ' },
        { label: 'Spintex Road Branch', value: 'Spintex Road Branch', description: 'Spintex Road Branch' },
        { label: 'Suame Branch', value: 'Suame Branch', description: 'Suame Branch' },
        { label: 'Sunyani Branch', value: 'Sunyani Branch', description: 'Sunyani Branch' },
        { label: 'Takoradi Market Circle Branch', value: 'Takoradi Market Circle Branch', description: 'Takoradi Market Circle Branch' },
        { label: 'Takoradi Harbour Branch', value: 'Takoradi Harbour Branch', description: 'Opposite European Hospital' },
        { label: 'Takyi Plaza Branch', value: 'Takyi Plaza Branch', description: 'Takyi Plaza Branch' },
        { label: 'Tamale Branch', value: 'Tamale Branch', description: 'Near Aboabo Market' },
        { label: 'Tamale Polytechnic Agency', value: 'Tamale Polytechnic Agency', description: 'GetFund Hostel' },
        { label: 'Tarkwa Branch', value: 'Tarkwa Branch', description: 'St. Matthews Roman Catholic Park' },
        { label: 'Tema Community 1 Branch', value: 'Tema Community 1 Branch', description: 'Tema Community 1 Branch' },
        { label: 'Tema Industrial Area Branch', value: 'Tema Industrial Area Branch', description: 'Tema Industrial Area Branch' },
        { label: 'Tema Metropolitan Assembly Branch', value: 'Trade Fair Branch', description: 'Burma Camp Road ' },
        { label: 'Trade Fair Branch', value: 'Trade Fair Branch', description: 'Burma Camp Road ' },
        { label: 'University For Development Studies (UDS) Agency', value: 'University For Development Studies (UDS) Agency', description: 'G035 Block C-UDS International Conference Centre' },
        { label: 'Winneba Agency', value: 'Winneba Agency', description: 'Co – operative Credit Union Complex - North Campus-University of Education, Winneba' }

      ],
      required
    }
  },
  methods: {
    async onComplete() {
      try {
        const starter = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone_number: this.phone,
          nss_id: this.nss_id,
          apply_ezwich: this.ezwich_application,
          user_id: this.user_id,
          bank_branch: this.bank_branch,
          loan_purpose: this.loan_purpose,

        }

        await this.useJwt()
            .sharedService
            .registerStarter(starter);
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Success`,
            icon: 'CoffeeIcon',
            variant: 'success',
            text: `You have successfully signed up for nss exxtra`,
          },
        });

        await this.$router.push({ name: 'kickstart-success' });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message;
        // console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: error_message,
          },
        });
        if (error_message === "Entry already exists") {
          await this.$router.push({ name: 'kickstart-registered' });
        }
      } finally {
        this.loading = false
      }
    },
    validatePersonalInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.personalInfo.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateContactInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.contactInfo.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateLoanInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.loanInfo.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  }
}
</script>

<style lang="scss">
  // override an unwanted inherited class
  .auth-wrapper .active {
    background-color: #144F6A00 !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0) !important;
  }
</style>
